@import 'colors';

$white: #ffffff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000000;

$grays: (
  '100': $gray-100,
  '200': $gray-200,
  '300': $gray-300,
  '400': $gray-400,
  '500': $gray-500,
  '600': $gray-600,
  '700': $gray-700,
  '800': $gray-800,
  '900': $gray-900
);

$blue: #5aa6e8;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #d63384;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #f3d42e;
$green: #198754;
$teal: #20c997;
$cyan: #0dcaf0;

$colors: (
  'blue': $blue,
  'indigo': $indigo,
  'purple': $purple,
  'pink': $pink,
  'red': $red,
  'orange': $orange,
  'yellow': $yellow,
  'green': $green,
  'teal': $teal,
  'cyan': $cyan,
  'white': $white,
  'gray': $gray-600,
  'gray-dark': $gray-800
);
// scss-docs-end colors-map

// scss-docs-start theme-color-variables
$primary: $blue;
$secondary: $gray-600;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-900;

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark
);

$min-contrast-ratio: 2.5;
$border-radius: 0.25rem;
$body-color: $gray-900;

$btn-box-shadow: drop-shadow(0px 8px 14px rgba(74, 166, 232, 0.45));

$box-border-radius: 5px;
$box-bottom-border-radius: 0 0 $box-border-radius $box-border-radius;
$box-border-radius-strong: 2 * $box-border-radius;

/* Inputs */
$input-font-size: 1rem;
$form-group-input-min-height: 2.8rem;

// Fonts
$font-serif: 'Roboto', sans-serif;
$font-condensed: 'Roboto Condensed', system-ui;

// icons
$icon-size: 0.5rem;
