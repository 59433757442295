@use '../../../../app-styles/variables';

:host {
  width: 100%;
  display: contents;

  .tree-select__label,
  .tree-select__option {
    color: variables.$baseFontColor;
  }

  .tree-select__option--disabled {
    opacity: 0.5;
  }

  .tree-select__option--has-children {
    font-weight: 500;
  }
}
