// Mixin to generate a single @font-face
// $font-family: Name of the font family
// $font-url: URL to the directory containing the font files
// $font-name: Name of the font file
// $font-weight: Weight of the font
// $font-style: Style of the font (default is normal)

@mixin font-src($font-family, $font-url, $font-name, $font-weight, $font-style: normal) {
  @font-face {
    font-family: $font-family;
    src: url('#{$font-url}#{$font-name}.woff') format('woff');
    font-display: block;
    font-style: $font-style;
    font-weight: $font-weight;
    font-stretch: normal;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074,
      U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
}

// Mixin to generate a collection of @font-face rules
// $font: Map containing font family details and an array of font sources

@mixin addFontFace($font) {
  @each $src in map-get($font, src) {
    $font-style: if(map-has-key($src, style), map-get($src, style), normal);
    @include font-src(map-get($font, family), map-get($font, url), map-get($src, font), map-get($src, weight), $font-style);
  }
}

// Directory structure for fonts:
// Place your font files in the appropriate directory, e.g., /src/app/assets/fonts/{FontFamily}/
// Example usage of the addFontFace mixin to create @font-face collections

@include addFontFace((family: 'Roboto',
    url: '/assets/fonts/Roboto/',
    src: ((font: 'Roboto-Thin', weight: 100, style: normal),
      (font: 'Roboto-ThinItalic', weight: 100, style: italic),
      (font: 'Roboto-Light', weight: 300, style: normal),
      (font: 'Roboto-LightItalic', weight: 300, style: italic),
      (font: 'Roboto-Regular', weight: 400, style: normal),
      (font: 'Roboto-Medium', weight: 500, style: normal),
      (font: 'Roboto-MediumItalic', weight: 500, style: italic),
      (font: 'Roboto-Bold', weight: 700, style: normal),
      (font: 'Roboto-BoldItalic', weight: 700, style: italic),
      (font: 'Roboto-Black', weight: 900, style: normal),
      (font: 'Roboto-BlackItalic', weight: 900, style: italic))));

@include addFontFace((family: 'Roboto Condensed',
    url: '/assets/fonts/RobotoCondensed/',
    src: ((font: 'RobotoCondensed-Regular', weight: 400, style: normal),
      (font: 'RobotoCondensed-RegularItalic', weight: 400, style: italic))));
