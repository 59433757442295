@use './variables';
@use 'bootstrap/scss/functions';

:root {
  /* Inputs */
  --lg-input-font-size: #{variables.$input-font-size};
  --lg-form-group-input-min-height: #{variables.$form-group-input-min-height};

  // Gradients and gradient colors
  @each $gradient-name, $gradient-colors in variables.$labgears-gradient {
    $color-from: map-get($gradient-colors, 'from');
    $color-to: map-get($gradient-colors, 'to');

    // ex. --lg-color-experiments-from, --lg-color-experiments-to
    --lg-color-#{$gradient-name}-from: #{$color-from};
    --lg-color-#{$gradient-name}-to: #{$color-to};

    // ex. --lg-gradient-research-objects, --lg-gradient-experiments, --lg-gradient-projects
    --lg-gradient-#{$gradient-name}: #{linear-gradient(
        variables.$gradient-angle,
        zip(map-values($gradient-colors), variables.$gradient-position)
      )};
  }
}
