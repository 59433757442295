@use 'variables' as var;

.icon-color-research-object {
  background-color: var(--lg-gradient-research-objects);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.icon-color-experiment {
  background-color: var(--lg-gradient-experiments);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.static-data {
  background-color: lightgreen;
}

.accordion {
  display: block;
  margin-bottom: 1.75rem;

  .card {
    background-color: var.$hover-white;
    border: none;
    box-shadow: 0px 0px 4px rgba(162, 189, 207, 0.5);

    &:not(:first-child) {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }

    &:not(:last-child) {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom: none;
    }
  }

  .card-header {
    border: 1px solid transparent;
    border-radius: var.$box-border-radius;
    font-size: 1rem;
    font-weight: 500;
    color: var.$baseFontColor;
    transition: all 0.5s;

    &:first-child {
      border-radius: var.$box-border-radius;
    }

    &:not(#parameters-panel-header,
      #instances-panel-header,
      #results-panel-header,
      #images-panel-header,
      #additional-parameters-panel-header):hover {
      color: var.$labgears-light-blue;
      border: 1px solid var.$labgears-light-blue;

      .item-details__panel-header {
        color: var.$labgears-light-blue;
      }
    }
  }

  .card-header,
  .catd-body {
    background-color: transparent;
  }
}

.reset-default-btn {
  border: none;
  background: none;
}

.overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.lg-dropdown-menu {
  &.lg-dropdown-menu--y-scroll {
    max-height: 200px;
    overflow-y: auto;

    &::-webkit-scrollbar-track {
      background: var.$labgears-white;
    }
  }
}

/* Item details */

.parameters-results__wrapper {
  width: 100%;
  max-height: 25rem;
  border: 1px solid var.$accordion-border-grey;
  border-radius: var.$box-border-radius;
  aspect-ratio: 16 / 9;
  margin: auto;
}

.parameters-results__wrapper--placeholder {
  height: 15rem;
}

.parameters-results__placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
}

.parameters-results__placeholder-text {
  color: var.$labgears-semi-dark-grey;
  text-align: center;
  line-height: 1.25rem;
  margin-top: 1rem;
}

.parameters-results__placeholder-icon {
  background-image: url('/assets/icons/parameters_results_placeholer_icon.svg');
  width: 4rem;
  height: 4rem;
  background-repeat: no-repeat;
  background-position: center;
}

.parameters-results__placeholder-link {
  color: var.$labgears-medium-blue;
  border: none;
  background: none;
  padding: unset;
  text-decoration: underline;
}

/* Scroll bar styles */

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--lightestgrey);
  width: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var.$labgears-medium-grey;
  border-radius: 5px;
  width: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var.$labgears-medium-blue;
}

/* Bootstrap carousel pagination buttons */
.carousel .carousel-indicators button {
  background-color: #a2bdcf;
}

.carousel .carousel-indicators .active {
  background-color: #89969f;
}

.carousel.carousel--pagination-on-hover {
  &:hover {
    .carousel-indicators {
      display: flex;
    }
  }

  .carousel-indicators {
    display: none;
  }
}

/* Handle opacity of dragged field */
body>.field-drag-wrapper__drag {
  opacity: 0.3;
}

.input-group button.btn-clear,
button.btn-clear {
  padding: 0.25rem;
  border: 1px solid transparent;
  color: var.$danger;
  background: url('/assets/icons/clear_icon.svg') transparent no-repeat center;
  background-size: contain;
  width: 0.85rem;
  height: 0.85rem;
  z-index: 5;
  position: absolute;
  top: 50%;
  right: 0.5rem;
  transform: translate(-50%, -50%);
}

.input-wrapper {
  position: relative;

  i {
    position: absolute;
    left: 1.25rem;
    top: 50%;
    z-index: 50;
    transform: translate3d(0, -50%, 0);
    font-size: 1.25rem;
    color: var.$baseFontColor;
  }

  input:focus {
    border: solid 1px var.$labgears-light-blue;
  }
}

.form-group {
  position: relative;

  ::placeholder {
    color: #b8c5d0;
  }

  input {
    font-size: var(--lg-input-font-size);
    background-repeat: no-repeat;
    background-position: 0.8rem center;
    color: var.$baseFontColor;
    min-height: var(--lg-form-group-input-min-height);
    background-size: 1.1rem;
    background-position-x: 0.7rem;
    border: 1px solid var.$labgears-semi-light-grey;
    border-radius: 6px;
    box-sizing: border-box;
    position: relative;

    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
    }
  }
}

/* Bootstrap ngb-modal */

body>ngb-modal-window.no-border>div.modal-dialog>div.modal-content {
  border: none;
}

.modal.files-upload-modal {
  .modal-dialog {
    --bs-modal-width: 940px;
  }
}

.modal-close {
  display: block;
  font-size: 1rem;
  line-height: 1;
  width: 1em;
  height: 1em;
  box-sizing: content-box;
  position: absolute;
  top: 1em;
  right: 1em;
  z-index: 1;
  padding: 0.25em;
}
