@use 'src/app/app-styles/variables' as var;

$ng-select-class: 'dropdown-select';

$ng-select-primary-text: var.$baseFontColor;
$ng-select-placeholder: var.$formControlPlaceholderColor;
$ng-select-value-font-size: var.$input-font-size;
$ng-select-height: var.$form-group-input-min-height;
$ng-select-border: var.$labgears-medium-grey;
$ng-select-highlight: var.$labgears-blue;

$ng-select-selected-text: var.$labgears-light-blue;
$ng-select-dropdown-border: var.$labgears-light-blue;
$ng-select-dropdown-option-text: var.$baseFontColor;

@import 'node_modules/@ng-select/ng-select/scss/default.theme';

.ng-invalid .ng-select.#{$ng-select-class}>.ng-select-container {
  border-color: var.$labgears-invalid-red;

  .ng-value-container .ng-placeholder {
    color: var.$labgears-invalid-red;
  }
}
.ng-valid .ng-select.#{$ng-select-class} > .ng-select-container {
  border-color: var.$labgears-semi-light-grey;

  .ng-value-container .ng-placeholder {
    color: var.$labgears-semi-light-grey;
  }
}

.ng-select.#{$ng-select-class} {
  --select-label-right: #{$ng-select-value-padding-left};

  &--clearable {
    --select-label-right: #{$ng-select-value-padding-left * 2};
  }

  &.ng-select-opened {
    --select-label-right: #{$ng-select-value-padding-left};

    >.ng-select-container {
      border-color: var.$labgears-light-blue;

      .ng-arrow {
        // Override styles
        top: 0;
        border-color: transparent;
        border-width: 0;

        transform: rotate(180deg);
        opacity: 1;

        &:hover {
          border-color: transparent;
        }
      }
    }
  }

  .ng-select-container {
    .ng-value-container {
      padding-right: var(--select-label-right);
    }
  }

  .ng-arrow-wrapper {
    // Override styles
    width: auto;
    padding-right: $ng-select-value-padding-left;

    // Custom styles
    position: relative;
    display: block;

    &:hover {
      .ng-arrow {
        border-color: transparent;
        opacity: 1;
      }
    }

    .ng-arrow {
      // Override styles
      border-color: transparent;
      border-style: solid;
      border-width: 0;

      // Custom styles
      display: block;
      width: 12px;
      height: 7px;
      position: relative;
      transition: 0.2s all ease-in-out;
      transform: rotate(0deg);
      transform-origin: center center;
      background: url('/assets/icons/chevron_down.svg') center center;
      background-size: 100%;
      opacity: 0.8;
    }
  }
}
