@use 'src/app/app-styles/variables';

.invalid {
  .ck.ck-toolbar {
    border-color: variables.$labgears-red;
  }
  .ck.ck-editor__main > .ck.ck-content.ck-editor__editable {
    border-right-color: variables.$labgears-red;
    border-bottom-color: variables.$labgears-red;
    border-left-color: variables.$labgears-red;
  }
}

div.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: variables.$labgears-grey;
  padding-left: 1em;
  padding-top: 0.8em;

  > p {
    margin-top: 0;
    margin-bottom: 0.1rem;
  }

  > p:last-child {
    padding-bottom: 1em;
  }
}

div.ck.ck-content.ck-editor__editable {
  padding-bottom: 10px;
}

div.ck.ck-sticky-panel > div.ck-sticky-panel__content > div.ck.ck-toolbar {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: transparent;
}

.ck-rounded-corners {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

button.ck.ck-button {
  padding-left: 10px;
  padding-right: 10px;
}

div.ck.ck-dropdown.ck-color-ui-dropdown > button.ck.ck-button.ck-dropdown__button {
  padding-left: 10px;
  padding-right: 10px;
}

.ck-editor__editable {
  min-height: 5em;
}

.characters-count {
  position: relative;
  top: -23px;
  font-size: 0.8rem;
  left: 0.7rem;
  color: variables.$icon-light-grey;
  padding-left: 0.3em;
}

.ck-content {
  font-size: 1rem;
  font-weight: 400;
}
