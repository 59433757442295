@use 'src/app/app-styles/variables';
@use 'src/app/app-styles/fonts';

// Bootstrap Configuration
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/utilities';

/* Bootstrap config */
$enable-negative-margins: true;

// Override bootstrap variables
$font-family-sans-serif: variables.$font-serif;
$input-btn-font-family: $font-family-sans-serif;
$form-check-input-checked-border-color: variables.$labgears-blue;
$form-check-input-checked-bg-color: variables.$labgears-blue;

// Button close bootstrap
$btn-close-color: variables.$baseFontColor;
$btn-close-bg: url('data:image/svg+xml,<svg viewBox="0 0 14 15" xmlns="http://www.w3.org/2000/svg" fill="#{$btn-close-color}"><path d="M7 9.71233L2.1031 14.413L0 12.3942L4.8969 7.69354L0 2.99292L2.1031 0.974121L7 5.67474L11.8969 0.974121L14 2.99292L9.1031 7.69354L14 12.3942L11.8969 14.413L7 9.71233Z" /></svg>'); // Source ~src/assets/icons/close_icon.svg
$btn-close-focus-shadow: none;
$btn-close-opacity: 1;

// Bootstrap Layout & components
@import 'bootstrap/scss/root';
@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/type';
@import 'bootstrap/scss/containers';
@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/buttons';
@import 'bootstrap/scss/transitions';
@import 'bootstrap/scss/dropdown';
@import 'bootstrap/scss/card';
@import 'bootstrap/scss/accordion';
@import 'bootstrap/scss/close';
@import 'bootstrap/scss/toasts';
@import 'bootstrap/scss/modal';
@import 'bootstrap/scss/tooltip';
@import 'bootstrap/scss/popover';
@import 'bootstrap/scss/carousel';
@import 'bootstrap/scss/spinners';

// Form components
@import 'bootstrap/scss/forms/labels';
@import 'bootstrap/scss/forms/form-text';
@import 'bootstrap/scss/forms/form-control';
@import 'bootstrap/scss/forms/form-select';
@import 'bootstrap/scss/forms/form-check';
@import 'bootstrap/scss/forms/input-group';
@import 'bootstrap/scss/forms/validation';
@import 'bootstrap/scss/helpers/clearfix';
@import 'bootstrap/scss/helpers/visually-hidden';
@import 'bootstrap/scss/helpers/text-truncation';
@import 'bootstrap/scss/helpers/vr';

// Utilities
@import 'bootstrap/scss/utilities/api';

// Core
body {
  height: 100%;
  line-height: initial;
  overflow: hidden;
}

html {
  height: 100%;
}

// Fonts
@import 'node_modules/bootstrap-icons/font/bootstrap-icons.scss';

// TODO: Does fontawesome.css need to exist for just two usages? (only in tags.component.html)
@import 'node_modules/@fortawesome/fontawesome-free/css/fontawesome.css';
@import 'node_modules/@fortawesome/fontawesome-free/css/solid.css';
@import 'node_modules/@fortawesome/fontawesome-free/css/regular.css';

// TODO: Should be consolidate these into general.scss?
@import 'app/app-styles/css-variables';
@import 'app/app-styles/ng-select';
@import 'app/app-styles/labgears-popover';
@import 'app/app-styles/labgears-navigation';
@import 'app/app-styles/wysiwyg-editor';
@import 'app/app-styles/general';

// TODO: Should be dynamically imported in components, except on the initial app load?
@import 'app/shared/dynamic-form/components/editor/editor.component.scss';
@import 'app/shared/_components/info-modal/info-modal.scss';
@import 'app/shared/_components/tree-select/tree-select/tree-select.component.scss';
