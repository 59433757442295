// CKeditor (do usunięcia jeśli zrezygnujemy z ckeditora)
.ck-text-12 {
  font-size: 0.75rem;
}

.ck-text-14 {
  font-size: 0.875rem;
}

.ck-text-16 {
  font-size: 1rem;
}

.ck-text-18 {
  font-size: 1.125rem;
}

.wysiwyg-popover {
  [class^="ck-text-"] {
    font-size: 0.75rem;
  }
}


