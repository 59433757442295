@use 'src/app/app-styles/variables';

.info-modal__body {
  margin: 1.5rem 0;
}

.info-modal__body-content {
  font-size: 0.875rem;
  font-weight: 400;
  color: variables.$labgears-semi-dark-grey;
  text-align: center;

  .content-highlight-blue {
    color: variables.$labgears-light-blue;
  }
}

.content-highlight-blue {
  color: variables.$labgears-light-blue;
}

.content-highlight-purple {
  color: variables.$lead-purple;
}

.info-modal--workspace {
  .info-modal__header__icon-container {
    background: variables.$labgears-light-blue;
  }
}
