@use 'src/app/app-styles/variables' as var;

$arrow-margin: 0.5rem;
$popover-arrow-border-shift: 1px;

@mixin info-button {
  border: none;
  background-image: url('/assets/icons/modal_section_additional_info_icon.svg');
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: center;
  width: 0.75rem;
  height: 0.75rem;
}

.popover.lg-popover {
  box-shadow: 0px 8px 18px 0px rgba(0, 0, 0, 0.25);
  filter: none;
  min-width: 7rem;
  max-width: 20rem;
  font-family: var.$font-serif;
  line-height: 0.875rem;

  &.lg-popover--with-sections {
    .popover-header {
      border-bottom: 1px solid var.$labgears-medium-grey;
      padding: 0.5rem 0.75rem;
    }
  }

  [divider] {
    background-color: transparent;
    border-top: 1px solid var.$labgears-medium-grey;
    margin: 0.25rem 0;
    opacity: unset;
  }

  a,
  button {
    padding-left: 0;
    padding-right: 0;
    border: 0;
    text-align: left;
    font-weight: 400;
    background-color: transparent;
    color: var.$baseFontColor;
    text-decoration: none;
    font-size: 0.75rem;

    &:not([disabled]):hover {
      color: var.$labgears-light-blue;
      text-decoration: underline;
    }

    &[disabled] {
      opacity: 0.5;
    }
  }

  ::ng-deep {
    .popover-header {
      background-color: var.$labgears-white;
    }
  }

  .popover-header {
    background-color: var.$labgears-white;
    border-bottom: none;
    padding: 0.5rem 0.5rem 0 0.5rem;
    font-size: 0.625rem;
    font-weight: 500;
    color: var.$baseFontColor;
    text-transform: uppercase;
  }

  .popover-body {
    padding: 0.5rem;
    font-size: 0.75rem;
    font-weight: 400;
    color: var.$labgears-semi-dark-grey;

    ul {
      list-style-type: none;
      padding-left: 0;
      margin-bottom: 0;
    }
  }
}

.lg-dropdown-menu {
  width: 100%;
}
