@use 'src/app/app-styles/variables';

.nav-actions {
  /**
   * Using a border width of 1.25px for --lg-action-spinner-border improves the visual appearance on high-density pixel screens (DPI).
   * This ensures lines are rendered more precisely and aesthetically, especially on Retina or 4K screens,
   * where even small differences in thickness are noticeable. The concept of "hairline" (the thinnest possible line)
   * was discussed in the W3C CSSWG thread, where methods for better rendering of thin lines on high-DPI screens were explored.
   * @link https://github.com/w3c/csswg-drafts/issues/3720
   */
  --lg-action-spinner-border: 1.5px;
  --lg-action-spinner-size: 0.8125rem;
  --lg-action-font-size: 0.75rem;
  --lg-action-icon-size: 0.8125rem;
  --lg-action-btn-padding: 0.25rem;
  --lg-action-gray-light: #{variables.$labgears-semi-light-grey};
  --lg-action-gray-dark: #{variables.$labgears-semi-dark-grey};
  --lg-action-blue-light: #{variables.$labgears-light-blue};
  --lg-action-blue-dark: #{variables.$labgears-dark-blue};
  --lg-action-transition-time: 0.15s;
  --lg-action-transition-ease: ease-in-out;

  font-size: var(--lg-action-font-size);
  color: var(--lg-action-gray-dark);

  .btn-spinner {
    transition: var(--lg-action-transition-time) opacity var(--lg-action-transition-ease);
    pointer-events: none;
    position: absolute;
    top: calc(50% + (var(--lg-action-spinner-border) / 2));
    left: calc(
      var(--bs-btn-border-width) + var(--lg-action-btn-padding) +
        (var(--lg-action-icon-size) / 2) - var(--lg-action-spinner-border)
    );
    transform: translate(-50%, -50%);
    opacity: 0;

    .spinner-action {
      --bs-spinner-width: var(--lg-action-spinner-size);
      --bs-spinner-height: var(--lg-action-spinner-size);
      --bs-spinner-border-width: var(--lg-action-spinner-border);
    }
  }

  .btn-action {
    font-size: var(--lg-action-font-size);
    --bs-btn-color: var(--lg-action-gray-dark);
    --bs-btn-hover-color: var(--lg-action-blue-light);
    --bs-btn-active-color: var(--lg-action-blue-light);
    --bs-btn-padding-y: var(--lg-action-btn-padding);
    --bs-btn-padding-x: var(--lg-action-btn-padding);
    --bs-btn-disabled-color: var(--lg-action-gray-light);
    --bs-btn-disabled-opacity: 1;

    display: inline-flex;
    align-items: center;
    text-decoration: none;
    position: relative;
    gap: var(--lg-action-btn-padding);
    transform: translateX(0);
    transition:
      var(--lg-action-transition-time) color var(--lg-action-transition-ease),
      var(--lg-action-transition-time) transform var(--lg-action-transition-ease);

    &:not(&.pending):hover {
      text-decoration: underline;
      color: var(--lg-action-blue-light);
    }

    .btn-label {
      position: relative;

      .label {
        opacity: 1;
        transition: var(--lg-action-transition-time) opacity var(--lg-action-transition-ease);
      }

      .label-active {
        color: var(--lg-action-gray-light);
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
        transition: var(--lg-action-transition-time) all var(--lg-action-transition-ease);
      }
    }

    &.pending {
      opacity: 1;
      color: var(--lg-action-gray-light);
      position: relative;
      transform: translateX(-0.5rem);

      app-icon {
        opacity: 0;
        transform: rotate(180deg) scale(0.65);
      }

      .btn-spinner {
        opacity: 1;
      }

      .btn-label {
        .label {
          opacity: 0;
        }

        .label-active {
          opacity: 1;
        }
      }
    }

    app-icon {
      opacity: 1;
      pointer-events: none;
      transition:
        var(--lg-action-transition-time) transform var(--lg-action-transition-ease),
        var(--lg-action-transition-time) opacity var(--lg-action-transition-ease);
      transform: rotate(0) scale(1);
    }
  }

  .btn-text {
    display: inline-block;
    vertical-align: middle;
    padding: var(--lg-action-btn-padding);
  }

  .col-vr {
    width: 1px;
    height: 1.25rem;
    background: var(--lg-action-gray-light);
    padding: 0;
    opacity: 0.75;
  }
}
