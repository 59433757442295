// refactor
$baseFontColor: #6c8190;

// BASIC
$labgears-white: #ffffff;
$border-white: #e4e7ec;
$hover-white: #f7f9fa;

$labgears-grey: #7a8790;

$labgears-medium-grey: #dbe4eb;
$icon-medium-grey: #a2bdcf;

$labgears-light-grey: #eff4f6;
$border-light-grey: #d3dce3;
$icon-light-grey: #b8c5d0;
$accordion-border-grey: #ccdbe4;
$backdrop-grey: #8a8a8a;

$labgears-red: #dc3545;
$labgears-invalid-red: #eb7171;
$labgears-green: #6fcf97;
$labgears-yellow: #f3d42e;
$labgears-orange: #fcb829;
$labgears-blue: #1a73e8;
$labgears-hover-blue: #add9fb;
$labgears-light-blue: #4aa6e8;
$labgears-medium-blue: #6ca7d9;
$labgears-black: #000000;

// STATUS
$status_pending_color: $labgears-grey;
$status_in-progress_color: $labgears-orange;
$status_review_color: $labgears-blue;
$status_finished_color: $labgears-green;
$status_cancelled_color: $labgears-red;

// FORMS
$formControlIconColor: #dadada;
$formControlPlaceholderColor: #b8c5d0;

// OTHER
$labgears-semi-dark-grey: #89969f;
$labgears-semi-light-grey: #d3d3d3;
$labgears-dark-grey: #5c6267;
$labgears-light-cyan: #e1f2f0;
$labgears-dark-blue: #1247a1;
$labgears-highlight-text-color: #094693;

// PROJECTS
$members-light-grey: #e8f0f7;

//
$labgears-very-light-grey: #eff4f6;
$labgears-lighter-grey: #e5e5e5;
$labgears-charcoal: #4b5358;
$labgears-dirty-white: #eff4f6;
$labgears-icon-grey: #c9ced6;
$labgears-placeholder-grey: #a6acbe;
$labgears-account-bar-color: #75b9e7;

$labgears-mcs-red: #f35325;
$labgears-mcs-green: #81bc06;
$labgears-mcs-blue: #05a6f0;
$labgears-mcs-yellow: #ffba08;

$labgears-related-green: #0fc992;
$labgears-gray-1: #f9fbfd;
$members-light-grey: #e8f0f7;
$lead-purple: #9869e3;
$upload-green: #0fc992;
$salmon: #ef8a52;

$labgears-option-orange: #fcb829;
$labgears-option-purple: #7b61ff;
$labgears-option-pink: #e772d4;
$labgears-option-brown: #9e6d4a;
$labgears-option-black: #393c40;

// Gradients
$labgears-gradient: (
  research-objects: (
    from: #1093bd,
    to: #0fc992
  ),
  experiments: (
    from: #9869e3,
    to: #329be7
  ),
  projects: (
    from: #de647c,
    to: #f19f5d
  )
);

// Extract color sets from the palette map
$colorResearchObjectsMap: map-get($labgears-gradient, research-objects);
$colorExperimentsMap: map-get($labgears-gradient, experiments);
$colorProjectsMap: map-get($labgears-gradient, projects);

// Gradient angle of direction
$gradient-angle: 242.99deg;

// Gradient color stop list
$gradient-position: (27.22%, 100%);

// Gradient names are already used throughout the application, so they must remain consistent.
// The zip function combines two lists element by element into pairs.
// Example: zip((red, blue, green), (25%, 50%, 75%)) => red 25%, blue 50%, green 75%
$researchObjectsBg: linear-gradient($gradient-angle, zip(map-values($colorResearchObjectsMap), $gradient-position));
$experimentsBg: linear-gradient($gradient-angle, zip(map-values($colorExperimentsMap), $gradient-position));
$projectsBg: linear-gradient($gradient-angle, zip(map-values($colorProjectsMap), $gradient-position));
